import { StateProvider } from './src/components/StateStore';

import './static/fonts/fonts.css';

export const wrapRootElement = ({ element }) => (
  <StateProvider>{element}</StateProvider>
);

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  // dont scroll for faq page
  const disableScroll = location.pathname === '/en/faqs/' || location.pathname === '/fr/faqs/';
  return !disableScroll;
};
